<template>
  <section class="invoice-add-wrapper add-news-wrapper">
    <b-row class="invoice-add">
      <b-col
        v-if="optionsChangelog === 'Creating' || optionsChangelog === 'Editing'"
        cols="12"
        xl="9"
        md="8"
      >
        <validation-observer ref="rules">
          <b-form @submit.prevent>
            <b-card
              no-body
              class="invoice-preview-card py-3"
            >
              <div style="position: absolute; top: 7px; right: 7px;">
                <div
                  :class="`badge badge-light-${optionsChangelogVariant()} ml-1`"
                  style="margin: 0 auto; width: fit-content; font-size: 15px;"
                >{{ optionsChangelog }}</div>
              </div>
              <!-- Details -->
              <b-card-body
                class="invoice-padding pt-0"
              >
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="body"
                      rules="required"
                    >
                      <label for="textarea-default">Body</label>
                      <quill-editor
                        v-model="isEdit"
                        :options="snowOption"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Please enter a news content."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >

        <!-- Action Buttons -->
        <b-card>
          <b-form-group class="mb-3">
            <label
              class="d-flex badge badge-light-primary justify-content-center"
              style="margin: 0 auto 5px auto; font-size: 15px; padding: 8px 0;"
              for="source"
            >Changelog List</label>
            <b-form-select
              id="source"
              v-model="valueChangelog"
              required
              placeholder="Select changelog"
              @change="changeChangelog"
            >
              <option
                v-for="log in changelogs"
                :key="log.id"
                :value="log"
              >
                {{ log.code == -1 ? 'Select changelog' : formattedDate(log.date) }}
              </option>
            </b-form-select>
          </b-form-group>
          <b-button
            v-if="optionsChangelog !== 'Creating'"
            variant="success"
            block
            @click="changeChangelogOption('Creating')"
          >
            Create new changelog
          </b-button>
          <b-button
            v-if="valueChangelog.code !== -1 && optionsChangelog !== 'Editing'"
            variant="outline-primary"
            block
            @click="changeChangelogOption('Editing')"
          >
            Edit changelog
          </b-button>
          <b-button
            v-if="valueChangelog.code !== -1"
            variant="danger"
            block
            @click="deleteChangelog"
          >
            Delete changelog
          </b-button>
        </b-card>
        <b-card>
          <b-button
            v-if="optionsChangelog === 'Creating'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            :disabled="sending"
            @click="addChangelog"
          >
            <span v-if="sending">
              <b-spinner
                small
                type="grow"
              />
              Creating...
            </span>
            <span v-else>Create</span>
          </b-button>
          <b-button
            v-if="optionsChangelog === 'Editing'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            :disabled="sending"
            @click="updateChangelog"
          >
            <span v-if="sending">
              <b-spinner
                small
                type="grow"
              />
              Saving...
            </span>
            <span v-else>Save</span>
          </b-button>
          <date-picker
            v-model="date"
            format="DD-MM-YYYY"
            type="date"
            value-type="format"
            class="w-100"
            placeholder="Date"
          />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
/* eslint-disable */
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'

import {
  BRow,
  BCol,
  BCard,
  BFormSelect,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  VBToggle,
  BSpinner,
} from 'bootstrap-vue'
import axios from '@/libs/axios'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormSelect,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    DatePicker,
    BFormInput,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      sending: false,
      content: '',
      scheduleDate: null,
      changelogs: [{ name: 'Select source', code: -1 }],
      valueChangelog: { name: 'Select source', code: -1 },
      date: null,
      optionsChangelog: 'Creating'
    }
  },
  computed: {
    isEdit: {
      get() {
        return this.optionsChangelog === 'Editing' ? this.valueChangelog.text : this.content
      },
      set(value) {
        this.content = value
      },
    },
  },
  mounted() {

  },
  created() {
    this.fetchChangelog()
  },
  methods: {
    fetchChangelog() {
      this.date = null;
      this.content = '';
      this.optionsChangelog = 'Creating';
      this.changelogs = [{ name: 'Select source', code: -1 }];
      this.valueChangelog = { name: 'Select source', code: -1 };
      axios.get('api/changelog').then(res => {
        if (res.data && res.data.success) {
          res.data.result.forEach(e => this.changelogs.push(e))
        }
      });
    },

    formattedDate(date) {
      return moment(date).format('DD MMMM YYYY');
    },

    changeChangelog() {
      if (this.valueChangelog.code == -1) {
        this.optionsChangelog = 'Creating';
        this.content = '';
        this.date = null;
      }
    },

    changeChangelogOption(option) {
      this.optionsChangelog = option;
      if (option === 'Creating') {
        this.content = '';
        this.date = null;
        this.valueChangelog = { name: 'Select source', code: -1 };
      }
      else if (option === 'Editing') {
        this.content = this.valueChangelog.text;
        this.date = moment(this.valueChangelog.date).format('DD-MM-YYYY');
      }
    },

    optionsChangelogVariant() {
      if(this.optionsChangelog === 'Creating') return 'success';
      else if (this.optionsChangelog === 'Editing') return 'warning';
      else return 'primary';
    },

    deleteChangelog() {
      this.confirmationAlert(`Are you sure you want to delete this changelog ?`).then(status => {
        if (status.isConfirmed) {
          axios.delete('api/changelog/delete/' + this.valueChangelog.id).then((res) => {
            if (res?.data?.success) {
              this.notify({
                text: 'Changelog deleted successfully!',
                variant: 'success',
              });
              this.fetchChangelog()
            } else {
              this.notify({
                text: 'Failed to delete changelog!',
                variant: 'danger',
              });
            }
          });
        }
      });
    },

    updateChangelog() {
      this.confirmationAlert(`Are you sure you want to update this changelog ?`).then(status => {
        if (status.isConfirmed) {
          this.$refs.rules.validate().then(success => {
            if (success) {
              const formData = new FormData();
              formData.append('id', this.valueChangelog.id);
              formData.append('content', this.content);

              if (this.date != null && this.date?.length > 0) formData.append('date', moment(this.date, "DD-MM-YYYY").format("YYYY-MM-DD"));
              else formData.append('date', moment(new Date().now()).format("YYYY-MM-DD"));
              
              const headers = { 'Content-Type': 'multipart/form-data' };
              this.sending = true;

              axios.post('api/changelog/update', formData, { headers }).then(res => {
                if (res?.data && res.data.success) {
                  this.notify({
                    text: 'The changelog has been updated !',
                    variant: 'info',
                  });
                  this.sending = false;
                  this.fetchChangelog();
                }
              }).catch(() => {
                this.notify({
                  text: 'Failed to update changelog!',
                  variant: 'danger',
                });
              });
            }
          });
        }
      });
    },

    addChangelog() {
      this.confirmationAlert(`Are you sure you want to create this changelog ?`).then(status => {
        if (status.isConfirmed) {
          this.$refs.rules.validate().then(success => {
            if (success) {
              this.$refs.rules.validate().then(success => {
                if (success) {
                  const formData = new FormData();
                  formData.append('content', this.content);

                  if (this.date != null && this.date?.length > 0) formData.append('date', moment(this.date, "DD-MM-YYYY").format("YYYY-MM-DD"));
                  else formData.append('date', moment().format("YYYY-MM-DD"));
                  
                  const headers = { 'Content-Type': 'multipart/form-data' };
                  this.sending = true

                  axios.post('/api/changelog/store', formData, { headers }).then(res => {
                    if (res?.data && res.data.success) {
                      this.notify({
                        text: 'The changelog has been added !',
                        variant: 'info',
                      });
                      this.sending = false;
                      this.fetchChangelog();
                    }
                  }).catch(() => {
                    this.notify({
                      text: 'Failed to add changelog!',
                      variant: 'danger',
                    });
                    this.sending = false;
                  })
                }
              });
            }
          });
        }
      });
    },
  },
}
</script>
 
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.add-news-wrapper .ql-editor {
  min-height: 320px;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
</style>
